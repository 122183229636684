import {Component, OnInit} from '@angular/core';
import {AlertService} from "../Shared/alert.service";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent implements OnInit{

  message: string = '';
  type: 'success' | 'error' | 'info' = 'info';
  isVisible: boolean = false;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertService.alert$.subscribe(alert => {
      this.message = alert.message;
      this.type = alert.type;
      this.isVisible = true;
      setTimeout(() => {
        this.isVisible = false;
      }, 5000);
    });
  }

  close() {
    this.isVisible = false;
  }
}
