import { NgModule } from '@angular/core';
import {SharedModule} from "../Shared/shared.module";
import {AlertComponent} from "./alert.component";



@NgModule({
  declarations: [AlertComponent],
  imports: [SharedModule],
  exports: [AlertComponent]
})
export class AlertModule {}
