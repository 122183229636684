import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, catchError, of} from "rxjs";
import { Router} from "@angular/router";
import {Token} from "../model/Token";
import {JwtHelperService} from "@auth0/angular-jwt";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtHelper: JwtHelperService;



  // Observable for token updates
  private tokenSubject = new BehaviorSubject<string | null>(null);
  public token$ = this.tokenSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  login(username: string, password: string): void {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    this.http.post(environment.api.baseUrl + environment.api.loginEndpoint, formData,{ withCredentials: true } ).subscribe(
      (response) => {
    this.requestAuthorizationCode();

      },
      (error) => {
        console.error('Login fehlgeschlagen:', error);
      }
    );
  }

  requestAuthorizationCode() {
    const codeChallenge = 'Kdy8KEBn5XDrgkGWJxoJBVB4azOh4yNoGexec1oSN1E';
    const codeChallengeMethod = 'S256';
    const clientId = 'oidc-client';

    const params = {
      response_type: 'code',
      client_id: clientId,
      code_challenge: codeChallenge,
      code_challenge_method: codeChallengeMethod
    };
    return this.http.get( environment.api.baseUrl+ environment.api.authorizationEndpoint, {
      // @ts-ignore
      responseType: "arraybuffer",
      params ,
      withCredentials:true,
      redirect: 'manual'
    })
      .subscribe(response => {
        console.log('Final response:', response);
      }, error => {
        if (error.status === 403 || error.status === 404){
          const codeMatch = error.message.match(/code=([A-Za-z0-9\-_]+)/);
          if (codeMatch && codeMatch[1]) {
            const extractedCode = codeMatch[1];
            this.exchangeCodeForToken(extractedCode);
          } else {
            console.log('No code found in the error message');
          }
        }
        else {
          console.log(error)
        }
      });
  }


  private exchangeCodeForToken(code: string): void {
    const codeVerifier = 'MTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTExMTE='
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new URLSearchParams();
    body.set('code', code);
    body.set('grant_type', 'authorization_code');
    body.set('client_id', "oidc-client")
    body.set('code_verifier', codeVerifier);

    this.http.post<Token>( environment.api.baseUrl + environment.api.tokenEndpoint, body.toString(), {headers, withCredentials:true})
      .subscribe(
        (data) => {
          sessionStorage.setItem('access_token', data.access_token);
          this.tokenSubject.next(data.access_token);
          this.router.navigate(['/home']);
        },
        (error) => {
          console.error('Token-Anforderung fehlgeschlagen:', error);
        }
      );
  }

  logout(){
    this.http.post( environment.api.baseUrl + "/logout", {}, {withCredentials:true}).pipe(
      catchError(error => {
        console.error('Logout failed:', error);
        return of(null);
      })
    ).subscribe(() => {
    });
    sessionStorage.removeItem('access_token');
    this.tokenSubject.next(null);

  }


  getUsernameFromToken(): string | null {
    try {
      // @ts-ignore
      const decodedToken = this.jwtHelper.decodeToken(this.getToken());
      return decodedToken.sub || null;
    } catch (error) {
      console.error('Invalid token', error);
      return null;
    }
  }

  getToken(): string | null {
    return  sessionStorage.getItem('access_token');
  }

  isLoggedIn() {
    return  sessionStorage.getItem('access_token') !== null;
  }




}
