import {ChangeDetectorRef, Component, inject, OnDestroy} from '@angular/core';
import {MediaMatcher} from "@angular/cdk/layout";
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnDestroy{

  navItems = [
    { label: 'OPEN CHANGE REQUESTS', icon: 'assignment', route: 'open-Requests' },
    { label: 'AWAITING RESPONSE', icon: 'hourglass_empty_outline', route: 'awating-response' },
    { label: 'CLOSED CHANGE REQUESTS', icon: 'check_circle_outline', route: 'closed-Requests' },

  ];


  collapsedNav: boolean = false;
  mobileQuery: MediaQueryList;
  button: boolean = false
  private authservice = inject(AuthService);
  private router = inject(Router);
  private _mobileQueryListener: () => void;


  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onMouseEnter() {
    if (this.button){
      this.collapsedNav = false;

    }
  }

  onMouseLeave() {
    if (this.button){
      this.collapsedNav = true;
    }
  }

  logout(){
    this.authservice.logout();
    this.router.navigate(['../login'])
  }

  getUserName(): string{
    return  <string>this.authservice.getUsernameFromToken()
  }

  protected readonly environment = environment;
}
